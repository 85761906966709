form.authentication {
  ion-list {
    margin-top: 1.5rem;

    ion-item {
      --background: var(--background-form-ion-item, white);
      --border-radius: 0.75rem;
      --min-height: 3.4rem;
      --inner-border-width: 0;
      font-weight: var(--fw-semi-bold);

      > ion-icon, > img {
        margin-right: 1rem;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

form.base {
  ion-item {
    padding-bottom: 1rem;

    ion-label.label-stacked {
      margin-top: 0;
      margin-bottom: 0.75rem;
      font-size: var(--fs-300);
      font-weight: var(--fw-bold);
    }

    &:has(ion-select), &:has(ion-datetime) {
      --background-hover: transparent;
      --background-focused: transparent;
    }
  }

  label {
    .label-text-wrapper {
      transform: none !important;
    }

    .label {
      padding-bottom: 0.75rem;
      font-size: var(--fs-300);
      font-weight: var(--fw-bold);
    }
  }

  ion-input, ion-select, ion-textarea {
    font-weight: var(--fw-semi-bold);
  }

  // ion-input
  ion-input label.input-wrapper {
    .native-wrapper {
      background-color: var(--background-form-element);
      padding: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
    }
  }

  // ion-textarea
  ion-textarea label .textarea-wrapper-inner {
    border-radius: 0.5rem;
    overflow: hidden;

    .native-wrapper {
      background-color: var(--background-form-element);
      padding: 0.5rem 1rem 1rem;
    }
  }

  // ion-select
  ion-select {
    background-color: var(--background-form-element);
    padding: 1rem;
    border-radius: 0.5rem;
  }

  // ion-datetime-button
  ion-datetime-button::part(native) {
    margin: 0 0.5rem 0 0;
    background-color: var(--background-form-element);
    font-weight: var(--fw-semi-bold);
  }
}
