ion-card {
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: none;

  &.can-hover:hover {
    cursor: pointer;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.05);
  }

  &.can-active:active {
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.12);
  }

  ion-card-header {
    padding: 1rem;
  }

  ion-card-content.card-content-ios, ion-card-content.card-content-md {
    padding: 0 1rem 1rem;
  }
}

ion-card.card-timeline-item {
  h2.title {
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 1.5rem;
    font-size: var(--fs-600);
    font-weight: var(--fw-bold);
  }

  inn-message-display-medias {
    display: block;
    margin-top: 1rem;
  }

  p.answer {
    margin-bottom: 0;
    line-height: 1.5rem;
    font-size: var(--fs-400);
  }

  ion-item.card-timeline-item-footer,
  ion-item.card-timeline-item-header {
    margin: 0 1rem;
    --min-height: 3.5rem;
    --inner-border-width: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
  }
}

ion-card.card-meeting-comments,
ion-card.card-meeting-description,
ion-card.card-meeting-details {
  display: flex;
  flex-direction: column;

  > ion-card-header {
    padding-top: 0;
    padding-bottom: 0;

    ion-item {
      --inner-padding-end: 0;
      --padding-start: 0;

      ion-label {
        margin: 1rem 1rem 1rem 0.5rem;
        font-weight: var(--fw-bold);
      }

      ion-icon {
        margin: 0;
      }
    }
  }

  ion-card-content {
    flex: 1;
    padding: 1rem;
  }
}
