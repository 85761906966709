ion-button {
  font-weight: var(--fw-bold);
}

ion-button:not(.square):not([shape="round"]) {
  --border-radius: 0.6rem;

  &:has(inn-avatar) {
    --background: transparent;

    &:hover {
      opacity: 0.9;
    }
  }
}

ion-button.square[shape="round"] {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-start: 0;
  margin: 0;
  width: 1.875rem;
  height: 1.875rem;
  min-height: 0;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }

  ion-icon {
    font-size: 1rem;
  }

  &.small {
    width: 2rem;
    height: 2rem;

    ion-icon {
      font-size: 1.25rem;
    }
  }

  &.medium {
    width: 2.5rem;
    height: 2.5rem;

    ion-icon {
      font-size: 1.5rem;
    }
  }

  &.large {
    width: 3rem;
    height: 3rem;

    ion-icon {
      font-size: 1.75rem;
    }
  }
}

ion-button.authentication.button-disabled {
  --background: rgba(var(--background-button-disabled-rgb), 0.75);
  opacity: 1;
}

.filters-buttons {
  display: flex;
  margin: 0 1rem;
  overflow: auto;

  ion-button {
    --min-height: 3rem;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    margin: 0;
    font-size: var(--fs-250);

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    ion-badge {
      --padding-end: 5px;
      --padding-start: 5px;
      min-width: 1rem;
      margin-left: 0.3rem;
      font-size: var(--fs-100);
    }
  }
}
