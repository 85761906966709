// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-border-color: #f0f3f4;
  --ion-divider-color: #dfe8ec;
  --ion-font-family: 'Eudoxus Sans', sans-serif;

  --answer-circle-progress-color: #eaeaea;
  --background-white-color: #ffffff;
  --background-surface-color: #f5f8f9;
  --background-layer-color: #f1f5f7;
  --background-image-cropper-color: #f1f5f7;
  --background-progress-bar-track: #f1f5f7;
  --background-sidenav-button-rgb: 229, 248, 255;
  --background-button-disabled-rgb: 189, 195, 199;
  --background-form-element: #f5f8f9;
  --background-form-ion-item: #ffffff;
  --background-edition-rbg: 250, 128, 114;
  --gallery-navigation-height: 5rem;

  --milestone-1: #fd5a84;
  --milestone-1-rgb: 253, 90, 132;
  --milestone-2: #f6c324;
  --milestone-2-rgb: 246, 195, 36;
  --milestone-3: #00a97b;
  --milestone-3-rgb: 0, 169, 123;
  --milestone-4: #ff9538;
  --milestone-4-rgb: 255, 149, 56;
  --meeting-default-background-color: #073749;
  --meeting-skeleton-background-color: #e6e6e6;

  --web-navbar-height: 4rem;
  --web-quest-instance-details-short-width: 380px;
  --web-settings-sidenav-width: 220px;
  --web-sidenav-width: 265px;
  --web-sidenav-width-collapsed: 80px;

  --fs-100: 0.625rem; // 10px
  --fs-150: 0.75rem; // 12px
  --fs-200: 0.8125rem; // 13px
  --fs-250: 0.875rem; // 14px
  --fs-300: 0.9375rem; // 15px;
  --fs-400: 1rem; // 16px;
  --fs-500: 1.0625rem; // 17px;
  --fs-550: 1.125rem; // 18px;
  --fs-600: 1.25rem; // 20px;
  --fs-700: 1.375rem; // 22px;
  --fs-800: 1.5rem; // 24px;
  --fs-900: 1.625rem; // 26px;

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;
  --fw-extra-bold: 800;

  --ion-color-primary: #1bc0ff;
  --ion-color-primary-rgb: 27, 192, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #18a9e0;
  --ion-color-primary-tint: #32c6ff;

  --ion-color-secondary: #fd5a84;
  --ion-color-secondary-rgb: 253, 90, 132;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #df4f74;
  --ion-color-secondary-tint: #fd6b90;

  --ion-color-tertiary: #ec8d39;
  --ion-color-tertiary-rgb: 236, 141, 57;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d07c32;
  --ion-color-tertiary-tint: #ee984d;

  --ion-color-success: #4ba67e;
  --ion-color-success-rgb: 75, 166, 126;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #42926f;
  --ion-color-success-tint: #5daf8b;

  --ion-color-warning: #eec54c;
  --ion-color-warning-rgb: 238, 197, 76;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d1ad43;
  --ion-color-warning-tint: #f0cb5e;

  --ion-color-danger: #ff3939;
  --ion-color-danger-rgb: 255, 57, 57;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e03232;
  --ion-color-danger-tint: #ff4d4d;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #232528;
  --ion-color-white-contrast-rgb: 35, 37, 40;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #232528;
  --ion-color-light-contrast-rgb: 35, 37, 40;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f0f1f1;

  --ion-color-medium: #687f87;
  --ion-color-medium-rgb: 104, 127, 135;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #5c7077;
  --ion-color-medium-tint: #778c93;

  --ion-color-dark: #232528;
  --ion-color-dark-rgb: 35, 37, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1f2123;
  --ion-color-dark-tint: #393b3e;
}

.ion-palette-dark.ios,
.ion-palette-dark.md {
  --answer-circle-progress-color: #3a3a3a;
  --background-white-color: #000000;
  --background-surface-color: #000000;
  --background-layer-color: #121212;
  --background-image-cropper-color: #000000;
  --background-progress-bar-track: #3a3a3a;
  --background-form-element: #121212;
  --background-form-ion-item: #1a1a1a;
  --background-button-disabled-rgb: 36, 36, 36;
  --meeting-default-background-color: #5b7481;
  --meeting-skeleton-background-color: #121212;

  --ion-border-color: #3a3a3a;
  --ion-divider-color: #3a3a3a;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #ff6b81;
  --ion-color-secondary-rgb: 255, 107, 129;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e05a70;
  --ion-color-secondary-tint: #ff7c92;

  --ion-color-tertiary: #ff9f43;
  --ion-color-tertiary-rgb: 255, 159, 67;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e08a3a;
  --ion-color-tertiary-tint: #ffb055;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-white: #222428;
  --ion-color-white-rgb: 34, 36, 40;
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-contrast-rgb: 255, 255, 255;
  --ion-color-white-shade: #1e2023;
  --ion-color-white-tint: #383a3e;
}

.ion-palette-dark.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-card-background: #1c1c1d;
  --ion-item-background: #000000;

  ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

.ion-palette-dark.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-border-color: #222222;
  --ion-card-background: #1e1e1e;
  --ion-item-background: #1e1e1e;
  --ion-tab-bar-background: #1f1f1f;
  --ion-toolbar-background: #1f1f1f;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}
