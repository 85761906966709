.ratio-container {
  position: relative;
  width: 100%;

  &.round {
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &.workshop-item-picture, &.quest-template-picture {
    aspect-ratio: 1;
  }

  .absolute {
    img, ion-img {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
    }

    ion-img {
      &::part(image) {
        object-fit: cover;
      }
    }
  }
}
