@use 'components/action-sheets';
@use 'components/alerts';
@use 'components/buttons';
@use 'components/cards';
@use 'components/chips';
@use 'components/colors';
@use 'components/footer';
@use 'components/forms';
@use 'components/header';
@use 'components/images';
@use 'components/inputs';
@use 'components/items';
@use 'components/lists';
@use 'components/modals';
@use 'components/popovers';
@use 'components/quill';
@use 'components/segments';
@use "components/select";
@use 'components/skeletons';
@use 'components/toats';
@use 'components/typography';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@ionic/angular/css/display.css';

/* Ionic Dark Palette */

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
@import '@ionic/angular/css/palettes/dark.class.css';
/* @import '@ionic/angular/css/palettes/dark.system.css'; */

// ion-content is for the mobile app
ion-content {
  --background: var(--background-surface-color);
}

// .router-outlet-wrapper is for the web app
.router-outlet-wrapper {
  position: relative;
  --background: var(--background-surface-color);
}

.fw-bold {
  font-weight: var(--fw-bold) !important;
}

.fw-semi-bold {
  font-weight: var(--fw-semi-bold) !important;
}

.fw-regular {
  font-weight: var(--fw-regular);
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.can-select {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.flex-available-space {
  flex: 1;
}

.align-items-center {
  height: 100%;
  display: flex;
  align-items: center;
}

.align-items-end {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.no-padding-top {
  --padding-top: 0 !important;
  padding-top: 0 !important;
}

.circle {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;

  &.primary {
    background-color: var(--ion-color-primary);
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
