ion-list.list-ios, ion-list.list-md {
  background: transparent;
}

.list-header:has(+ ion-list.settings), .list-header:has(+ inn-avatar-upload) {
  margin-top: 1.5rem;
}

ion-list.settings {
  margin: 0 1rem;
  background: var(--ion-background-color, white);
  border-radius: var(--border-radius);

  ion-item {
    --border-color: var(--ion-border-color);
    --min-height: 3.2rem;
    font-weight: var(--fw-semi-bold);

    &:last-child {
      --inner-border-width: 0;
    }
  }

  ion-item {
    > ion-icon, > img {
      margin-right: 1rem;
    }
  }

  &.has-top-margin {
    margin-top: 1.5rem;
  }

  &.sub {
    ion-radio {
      --color-checked: var(--ion-color-white);
    }

    ion-radio::part(container) {
      width: 1.3rem;
      height: 1.3rem;
      border: 1.5px solid var(--ion-color-primary);
      border-radius: 50%
    }

    .radio-checked::part(container) {
      background: var(--ion-color-primary);
    }
  }
}

ion-list:not(:last-child) {
  margin-bottom: 1rem;
}

.popover-viewport ion-list:not(:last-child) {
  margin-bottom: 0;
}
