ion-header {
  ion-toolbar ion-title {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  ion-toolbar:has(inn-logo) {
    padding: 0 1rem;
  }
}

.header-collapse-condense {
  ion-toolbar {
    --background: transparent;

    ion-title {
      padding-inline-start: 1rem;
      padding-inline-end: 0;
      text-align: left;
      font-size: var(--fs-800);
      font-weight: var(--fw-bold);
    }
  }
}

ion-modal ion-header ion-toolbar, ion-header.transparent ion-toolbar {
  --background: transparent;
}

ion-header.transparent ion-toolbar {
  --background: transparent;
}

