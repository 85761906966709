.ql-container {
  font-family: "Eudoxus Sans", sans-serif;

  .ql-editor {
    line-height: 1.5rem;
    font-size: var(--fs-400);

    p, ul, ol, ul li, ol li {
      margin: 0;
      line-height: 1.5rem;
      color: unset;
      font-size: unset;
    }

    p:empty {
      min-height: 1.5rem;
    }

    blockquote {
      margin: 0;
      padding-left: 1rem;
      border-left: 0.25rem solid var(--ion-color-medium);
    }

    a {
      color: var(--ion-color-primary);
      font-weight: var(--fw-semi-bold);
    }
  }
}
