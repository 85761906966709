ion-chip {
  @for $i from 1 through 4 {
    &.milestone-#{$i} {
      background-color: var(--milestone-#{$i});
    }
  }

  font-weight: var(--fw-bold);
}

ion-chip.light {
  background-color: var(--background-surface-color);
  font-weight: var(--fw-semi-bold);
}
