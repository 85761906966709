ion-toast {
  b {
    font-weight: var(--font-weight-semi-bold);
  }
}

.danger-toast {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-white);
}

.success-toast {
  --background: var(--ion-color-success);
  --color: var(--ion-color-white);
}

.warning-toast {
  --background: var(--ion-color-warning);
  --color: var(--ion-color-dark);
}
