ion-modal:has(+ ion-modal) {
  &::part(content) {
    opacity: 0;
  }
}

ion-modal {
  ion-header ion-toolbar ion-buttons ion-button:not(.square) {
    --padding-start: 0.75rem !important;
    --padding-end: 0.75rem !important;
    --border-radius: 0.5rem !important;
  }

  ion-header.header-collapse-condense ion-toolbar {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  ion-content {
    --background: var(--ion-card-background);
  }

  ion-footer ion-toolbar:first-of-type {
    padding-top: 0 !important;
  }
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --min-width: 100%;
  --min-height: 100%;
  --border-radius: 0;
}

ion-modal.filters, ion-modal.auto-height {
  --height: auto;

  .ion-page {
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

@media only screen and (min-width: 600px) and (min-height: 600px) {
  ion-modal.web-settings {
    --width: 800px;
    --height: 80%;
    --min-height: 600px;
  }

  ion-modal.modal-image-cropper {
  }
}

ion-modal.settings ion-content {
  --background: var(--background-surface-color);
}
