h1.title {
  margin: 1rem 0;
  font-size: var(--fs-900);
  font-weight: var(--fw-bold);

  &.center {
    justify-content: center;
    text-align: center;
  }
}

.web {
  h1.title {
    display: flex;
    align-items: center;
    min-height: 3.25rem;
    margin: 1.5rem 1rem;
    font-size: var(--fs-800);
  }
}
