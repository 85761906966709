ion-segment.base {
  --background: transparent;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  border-radius: 0;

  ion-segment-button {
    --indicator-box-shadow: none;
    --border-radius: 0;
    --padding-end: 0;
    --padding-start: 0;
    margin: 0;
    min-height: 44px;
    min-width: 0;
    font-size: var(--fs-400);
    font-weight: var(--fw-bold);

    &::part(indicator) {
      height: 100%;
      padding-inline-start: 0;
      padding-inline-end: 0;
      border-bottom: 3px solid var(--ion-color-primary);
      min-width: 0;
    }

    &::part(indicator-background) {
      background: transparent;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

ion-segment.notifications {
  --background: var(--background-white-color);
  border-bottom: 1px solid var(--ion-border-color);
  border-radius: 0;

  ion-segment-button {
    --indicator-color: var(--background-white-color);
    --indicator-box-shadow: none;
    --border-radius: 0;
    margin: 0;
    min-height: 44px;
    font-size: var(--fs-300);

    ion-badge {
      margin-left: 0.5rem;
    }

    &::before {
      border-left: 0;
    }

    &.segment-button-checked {
      font-weight: var(--fw-bold);
    }

    &::part(indicator) {
      height: 100%;
      padding-inline-start: 0;
      padding-inline-end: 0;
      border-bottom: 2px solid var(--ion-color-primary);
      min-width: 0;
    }
  }
}
