ion-popover ion-content {
  --background: initial;
}

.popover-menu {
  ion-list {
    ion-item {
      --border-color: var(--ion-border-color);

      &:last-child {
        --inner-border-width: 0;
      }

      ion-icon {
        margin-right: 1rem;
      }

      ion-label {
        font-weight: var(--fw-semi-bold);
      }
    }
  }
}

ion-popover.filters {
  --width: auto;
  // --min-width: 350px;
  --box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.05);
  --offset-y: 10px;
}

ion-popover.web-notifications-popover {
  --min-width: 460px;
}
